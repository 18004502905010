import React from "react";
import Layout from "../components/layout";
import useIncidents from "../hooks/useIncidents";
import useLocale from "../hooks/useLocale";
import DataFilters from "../components/data/filters";
import { mapFilters } from "../style/theme";
import SEO from "../components/seo";
import Map from "../components/map";

export default function YemeniMap() {
  const { incidents, setFilters, filters } = useIncidents();
  const setCurrentPage = () => null;
  const { isAra } = useLocale();

  return (
    <div
      css={{
        position: "relative",
        left: isAra ? "auto" : "50%",
        right: isAra ? "50%" : "auto",
        transform: isAra ? " translateX(50%)" : "translateX(-50%)",
        width: "100vw",
        marginTop: "2rem",
        marginBottom: "2rem",
      }}
    >
      <Map
        incidents={incidents}
        viewport={{
          lat: 15.5,
          lng: 46,
          zoom: 7,
        }}
      />
    </div>
  );
}
