export const englishLocation = [
  { id: 1, title: "Abyan" },
  { id: 2, title: "Ad-Dali" },
  { id: 3, title: "Aden" },
  { id: 4, title: "Amran" },
  { id: 5, title: "Baqam" },
  { id: 6, title: "Bayda" },
  { id: 7, title: "Hajja" },
  { id: 8, title: "Hudaydah" },
  { id: 9, title: "Jawf" },
  { id: 10, title: "Lahj" },
  { id: 11, title: "lahj" },
  { id: 12, title: "Marib" },
  { id: 13, title: "Saada" },
  { id: 14, title: "Sanaa" },
  { id: 15, title: "Shabwa" },
  { id: 16, title: "Shabwah" },
  { id: 17, title: "Taiz" },
  { id: 18, title: "Ibb" },
  { id: 19, title: "Dhamar" },
  { id: 20, title: "Hadhramaut " },
  { id: 21, title: "UNKNOWN" },
];

export const allegedPerpetrator = [
  { id: 0, title: "Houthis Rebel movement" },
  { id: 1, title: "AQAP" },
  { id: 2, title: "Pro- Coalition forces" },
  { id: 3, title: "Yemeni GOV forces" },
  { id: 4, title: "Saudi-led coalition" },
  { id: 5, title: "ISIS" },
  { id: 6, title: "UNKNOWN" },
];
export const areaOfControl = [
  { id: 0, title: "Yemeni GOV forces" },
  { id: 1, title: "Houthis Rebel movement" },
  { id: 2, title: "AQAP" },
  { id: 3, title: "Pro-Coalition forces" },
  { id: 4, title: "ISIS" },
  { id: 5, title: "Tribal militias" },
  { id: 6, title: "Disputed areas" },
  { id: 7, title: "UNKNOWN" },
];
export const isDirectHit = [
  { id: 0, title: "TRUE" },
  { id: 1, title: "FALSE" },
  { id: 2, title: "UNKNOWN" },
];
export const hasCasualties = [
  { id: 0, title: "TRUE" },
  { id: 1, title: "FALSE" },
  { id: 2, title: "UNKNOWN" },
];

export const deliveryMethod = [
  { id: 0, title: "UNKNOWN" },
  { id: 1, title: "Unknown munition" },
  { id: 2, title: "Unknown shrapnel" },
  { id: 3, title: "Air strike" },
  { id: 4, title: "Artillery" },
  { id: 5, title: "Rocket Artillery" },
  { id: 6, title: "Rockets launchers" },
  { id: 7, title: "Mortar" },
  { id: 8, title: "Placed explosive" },
  { id: 9, title: "Landmine" },
  { id: 10, title: "Execution" },
  { id: 11, title: "Small arms" },
];

export const allLocations = [];
