import React from "react";
import { split, last } from "ramda";

function Video({ src, ...attributes }) {
  const format = last(split(".", src));
  return (
    <>
      <video controls width="100%" {...attributes}>
        <source src={src} type={`video/${format}`} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </>
  );
}

export default Video;
