import { useState, useEffect, useMemo } from "react";
//import Fuse from "fuse.js";
import { useStaticQuery, graphql } from "gatsby";
import {
  allPass,
  equals,
  filter,
  includes,
  isEmpty,
  where,
  isNil,
  anyPass,
  propSatisfies,
  or,
} from "ramda";
import { parse, format } from "date-fns";
import { isBefore, isAfter } from "date-fns/fp";
import useDataApi from "./useDataApi";
import { capitalizeEachWord } from "../utils/helper";
import useLocale from "./useLocale";
const config = require(`../../config/i18n`);

export const defaultFilters = {
  title: null,
  dataset: null,
  dateBefore: null,
  dateAfter: null,
  casualties: null,
  directHit: null,
  areaOfControl: null,
  deliveryMethod: null,
  allegedPerpetrator: null,
  filterType: "all",
};

export default function useIncidents() {
  const { locale } = useLocale();
  const { apiAuthHeader, apiUrl } = config;
  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    `${apiUrl}/yemen/${locale}/incidents`,
    [],
    apiAuthHeader
  );

  const [incidents, setIncidents] = useState([]);
  const [allIncidents, setAllIncidents] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const allIncidents =
      data?.data?.map((o) => ({
        ...o,
        formattedDate: o?.date
          ? format(new Date(o?.date), "yyyy-MM-dd")
          : o?.date,
        date: new Date(o.date),
        title: capitalizeEachWord(o.title),
        lowerCaseTitle: o.title.toLowerCase(),
      })) || [];
    const JOincidents = allIncidents.filter((dt) => dt?.dataset === "JO") || [];
    setIncidents(JOincidents);
    setAllIncidents(JOincidents);
  }, [data]);

  useMemo(() => {
    const hasTitle = where({
      lowerCaseTitle: includes(filters.title?.toLowerCase()),
    });
    const withDeliveryMethod = where({
      deliveryMethod: equals(filters.deliveryMethod),
    });
    const withAllegedPerpetrator = where({
      allegedPerpetrator: equals(filters.allegedPerpetrator),
    });
    const underAreaOfControl = where({
      areaOfControl: equals(filters.areaOfControl),
    });

    const isDirectHit = where({ directHit: equals(filters.directHit) });

    const hasCasualites = where({
      casualties: equals(filters.casualties),
    });
    const afterDate = where({ date: isAfter(filters.dateAfter) });
    const beforeDate = where({ date: isBefore(filters.dateBefore) });

    const listOfPredicates = [];

    !isNil(filters.title) && listOfPredicates.push(hasTitle);
    !isNil(filters.deliveryMethod) && listOfPredicates.push(withDeliveryMethod);
    !isNil(filters.allegedPerpetrator) &&
      listOfPredicates.push(withAllegedPerpetrator);
    !isNil(filters.areaOfControl) && listOfPredicates.push(underAreaOfControl);
    !isNil(filters.directHit) && listOfPredicates.push(isDirectHit);
    !isNil(filters.casualties) && listOfPredicates.push(hasCasualites);
    !isNil(filters.dateBefore) && listOfPredicates.push(beforeDate);
    !isNil(filters.dateAfter) && listOfPredicates.push(afterDate);

    const allPassedIncidents = allPass(listOfPredicates);
    const anyPassedIncidents = anyPass(listOfPredicates);

    const filteredIncidents =
      filters.filterType === "all" ? allPassedIncidents : anyPassedIncidents;
    setIncidents(filter(filteredIncidents, allIncidents));
  }, [filters]);

  return {
    incidents,
    setFilters,
    filters,
    setSearchQuery,
    isLoading,
  };
}
