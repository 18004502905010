import React from "react";
import styled from "@emotion/styled";
import { MDXProvider } from "@mdx-js/react";
import MdxLink from "./mdx-link";
import { colors } from "../../style/theme";
import { mq } from "../../utils/helper";
import useLocale from "../../hooks/useLocale";

export default function Toi({ children, img, height, onePiece = false }) {
  const { isAra } = useLocale();
  const confidenceRatings = onePiece && height === "100vh";

  return (
    <div
      css={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundColor: "rgb(100,100,100,0)",
        position: "relative",
        left: isAra ? "auto" : "50%",
        right: isAra ? "50%" : "auto",
        transform: isAra ? " translateX(50%)" : "translateX(-50%)",
        width: "100vw",
        height: height,
        marginTop: onePiece ? 0 : "2rem",
        marginBottom: onePiece ? 0 : "2rem",
        padding: "2rem",
        lineHeight: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: onePiece ? "top" : "center",
        [mq[0]]: {
          display: "block",
          width: "auto",
          height: "auto",
          background: "none",
          padding: 0,
          marginBottom: 0,
          backgroundColor: confidenceRatings ? "black" : "unset",
        },
      }}
    >
      <div
        css={{
          width: confidenceRatings ? "auto" : "966px",
          margin: "0 auto",
          paddingBottom: "3rem",
          /* marginBottom: rhythm(4), */
          justifySelf: "center",
          color: colors.light,
          [mq[0]]: {
            width: "auto",
            color: colors.black,
            paddingBottom: 0,
          },
        }}
      >
        <MDXProvider
          components={{
            h2: styled.h2({
              color: colors.light,
              fontWeight: 800,
              [mq[0]]: {
                color: colors.black,
              },
            }),
            p: styled.p({
              color: colors.light,
              fontSize: "23px",
              marginBottom: "1.7rem",
              [mq[0]]: {
                fontSize: "20px",
                color: colors.black,
              },
            }),
            a: styled(MdxLink)({
              color: colors.light,
              fontWeight: "bold",
              ":hover": {
                color: colors.primary,
              },
              [mq[0]]: {
                color: colors.black,
              },
            }),
          }}
        >
          {children}
        </MDXProvider>
      </div>
    </div>
  );
}
