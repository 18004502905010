import React from "react";
import { Link } from "gatsby";
import { colors } from "../style/theme";
import useLocale from "../hooks/useLocale";

export default function LocalizedLink({ to, ...props }) {
  const { isAra, locale } = useLocale();
  const path = !isAra ? `/${to}` : `/${locale}/${to}`;
  return (
    <Link
      {...props}
      to={path}
      css={{
        color: colors.black,
        ":hover": {
          color: colors.primary,
          textDecoration: "none",
        },
      }}
    />
  );
}
