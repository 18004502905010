import React from "react";
import { Bar } from "react-chartjs-2";
import { mq } from "../utils/helper";

const data = {
  labels: [
    "Houthis Rebel movement",
    "Pro- Coalition forces",
    "Yemeni GOV forces",
    "Tribal militias",
    "Disputed areas",
  ],
  datasets: [
    {
      label: "Area of Control",
      data: [77, 28, 61, 1, 35],
      backgroundColor: [
        "rgba(175, 27, 67, 1)",
        "rgba(4, 110, 145, 1)",
        "rgba(132, 140, 73, 1)",
        "rgba(166, 51, 93, 1)",
        "rgba(119, 69, 75, 1)",
      ],
      borderColor: [
        "rgba(175, 27, 67, 1)",
        "rgba(4, 110, 145, 1)",
        "rgba(132, 140, 73, 1)",
        "rgba(166, 51, 93, 1)",
        "rgba(119, 69, 75, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const legendOpts = {
  labels: {
    fontColor: "",
  },
};

const options = {
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#666",
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: "white",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: "white",
        },
      },
    ],
  },
};

const AreaOfControl = () => (
  <div
    css={{
      position: "relative",
      zIndex: 3,
      /* backgroundColor: "black", */
      marginTop: "2rem",
      marginBottom: "2rem",
      [mq[0]]: {
        width: "100%",
      },
    }}
  >
    <Bar data={data} options={options} />
  </div>
);

export default AreaOfControl;
