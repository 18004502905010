import React, { useEffect } from "react";
import Select from "react-select";
import DateFilters from "./date-filters";
import {
  englishLocation,
  areaOfControl,
  deliveryMethod,
  allegedPerpetrator,
  isDirectHit,
  hasCasualties,
} from "../../utils/lists";
import { Form } from "react-bootstrap";
import { BsTable } from "react-icons/bs";
import useTranslation from "../../hooks/useTranslations";
import useLocale from "../../hooks/useLocale";
import LocalizedLink from "../localizedLink";
import { datePickerStyleTable } from "../../style/theme";
import { capitalizeEachWord } from "../../utils/helper";

export default function DataFilters({
  setFilters,
  filters,
  style,
  setCurrentPage,
}) {
  const tr = useTranslation();

  return (
    <div css={style.outerDiv}>
      <div css={style.innerDiv}>
        <Form.Control
          value={filters.title}
          placeholder={tr("Type to search..")}
          css={style.search}
          onChange={(event) => {
            const value = event.target.value;
            setCurrentPage(1);
            setFilters((prevState) => ({
              ...prevState,
              title: value || "",
            }));
          }}
        />
        <Select
          placeholder={tr("Delivery method")}
          isClearable
          styles={style.select}
          onChange={(selection) => {
            setCurrentPage(1);
            setFilters((prevState) => ({
              ...prevState,
              deliveryMethod: selection?.title,
            }));
          }}
          getOptionLabel={(e) => e.title}
          getOptionValue={(e) => e.id}
          options={deliveryMethod}
        />
        <Select
          placeholder={tr("Alleged perpetrator")}
          isClearable
          styles={style.select}
          onChange={(selection) => {
            setCurrentPage(1);
            setFilters((prevState) => ({
              ...prevState,
              allegedPerpetrator: selection?.title,
            }));
          }}
          getOptionLabel={(e) => e.title}
          getOptionValue={(e) => e.id}
          options={allegedPerpetrator}
        />
        {/* <div css={style.break}></div> */}
        <Select
          placeholder={tr("Area of control")}
          isClearable
          styles={style.select}
          onChange={(selection) => {
            setCurrentPage(1);
            setFilters((prevState) => ({
              ...prevState,
              areaOfControl: selection?.title,
            }));
          }}
          getOptionLabel={(e) => e.title}
          getOptionValue={(e) => e.id}
          options={areaOfControl}
        />
        <Select
          placeholder={tr("Direct hit")}
          isClearable
          styles={style.select}
          onChange={(selection) => {
            setCurrentPage(1);
            setFilters((prevState) => ({
              ...prevState,
              directHit: selection?.title,
            }));
          }}
          getOptionLabel={(e) => e.title}
          getOptionValue={(e) => e.id}
          options={isDirectHit}
        />
        <Select
          placeholder={tr("Casualties")}
          isClearable
          styles={style.select}
          onChange={(selection) => {
            setCurrentPage(1);
            setFilters((prevState) => ({
              ...prevState,
              casualties: selection?.title,
            }));
          }}
          getOptionLabel={(e) => e.title}
          getOptionValue={(e) => e.id}
          options={hasCasualties}
        />
        <DateFilters
          setCurrentPage={setCurrentPage}
          setFilters={setFilters}
          style={style.datePicker}
        />
        <div css={style.switch}>
          <LocalizedLink to="data">
            {tr("Switch to Table View")}
            <span
              css={{
                marginLeft: "0.4rem",
                marginRight: "0.4rem",
              }}
            >
              <BsTable />
            </span>
          </LocalizedLink>
        </div>
      </div>
    </div>
  );
}
