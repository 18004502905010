import React from "react";
import { Line } from "react-chartjs-2";

const state = {
  labels: [
    "2015-Jan",
    "2015-Jan",
    "2015-Mar",
    "2015-Apr",
    "2015-May",
    "2015-Jul",
    "2015-Aug",
    "2015-Sep",
    "2015-Oct",
    "2015-Nov",
    "2015-Dec",
    "2016- Jan",
    "2016-Feb",
    "2016-Mar",
    "2016-Apr",
    "2016-May",
    "2016-Jun",
    "2016-Jul",
    "2016-Aug",
    "2016-Sep",
    "2016-Oct",
    "2016-Nov",
    "2016-Dec",
    "2017- Jan",
    "2017-Feb",
    "2017-Apr",
    "2017-May",
    "2017-Jun",
    "2017-Jul",
    "2017-Oct",
    "2017-Nov",
    "2017-Dec",
    "2018-Jan",
    "2018-Feb",
    "2018-Mar",
    "2018-Apr",
    "2018-Jun",
    "2018-Jul",
    "2018-Sep",
    "2018-Oct",
    "2018-Nov",
    "2019-Jan",
    "2019-Mar",
    "2019-May",
    "2019-Jun",
    "2019-Jul",
    "2019-Sep",
    "2020-Jun",
    "2020-Dec",
    "2021-Jan",
  ],

  datasets: [
    {
      label: "SLC",
      fill: false,
      lineTension: 0,
      borderColor: "#046E91",
      borderWidth: 2,
      pointBorderWidth: 0,
      data: [
        0, 0, 4, 5, 0, 5, 3, 1, 3, 0, 4, 4, 0, 0, 0, 1, 1, 5, 2, 2, 3, 2, 6, 2,
        0, 2, 0, 1, 1, 1, 1, 1, 3, 2, 2, 0, 3, 3, 0, 0, 0, 1, 2, 1, 1, 1, 0, 0,
        0,
      ],
      options: {
        scales: {
          yAxes: [
            {
              stacked: false,
            },
          ],
        },
      },
    },
    {
      label: "Houthi",
      fill: false,
      lineTension: 0,
      backgroundColor: "rgba(200, 46, 81, 0.3)",
      borderColor: "#AF1B43",
      borderWidth: 2,
      pointBorderWidth: 0,
      data: [
        1, 2, 3, 0, 1, 2, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1,
        1, 2, 2, 0, 0, 0, 1, 3, 0, 0, 3, 1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 0, 0, 1,
        1,
      ],
      options: {
        scales: {
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    },
  ],
};

const options = {
  gridLines: {
    drawOnArea: true,
  },
};

export default function AttacksLineChart() {
  return (
    <div
      css={{
        position: "relative",
        zIndex: 3,
        /* backgroundColor: "black", */
        marginTop: "2rem",
        marginBottom: "2rem",
      }}
    >
      <Line data={state} options={options} />
    </div>
  );
}
