import { useEffect, useState } from "react";

export default function usePath() {
  const [path, setPath] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setPath(window.location.pathname);
    }
  }, []);

  return { path };
}
