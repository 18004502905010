import React from "react";
import styled from "@emotion/styled";
import { FaQuoteLeft } from "react-icons/fa";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "./mdx-link";
import { mq } from "../../utils/helper";
import { colors } from "../../style/theme";

export default function Quote({ children, quotee }) {
  return (
    <div
      css={{
        border: `1px solid ${colors.black}`,
        borderRadius: "15px",
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: "3rem",
        lineHeight: "32px",
        position: "relative",
        fontSize: "30px",
        fontFamily: "Georgia",
        [mq[0]]: {
          padding: "1rem",
          paddingBottom: "3rem",
          fontSize: "22px",
          lineHeight: "auto",
        },
      }}
    >
      <div
        css={{
          position: "absolute",
          top: "-19px",
          fontSize: "2rem",
          color: colors.primary,
          left: "50%",
        }}
      >
        <FaQuoteLeft />
      </div>
      <MDXProvider
        components={{
          a: styled(MdxLink)({
            color: colors.primary,
            fontWeight: "bold",
            ":hover": {
              color: colors.primary,
            },
          }),
        }}
      >
        {children}
      </MDXProvider>
      <div
        css={{
          position: "absolute",
          bottom: "-8%",
          right: "5%",
          backgroundColor: colors.light,
          zIndex: 4,
          fontSize: "23px",
          [mq[0]]: {
            fontSize: "18px",
          },
        }}
      >
        {quotee}
      </div>
    </div>
  );
}
