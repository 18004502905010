import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import useLocale from "../hooks/useLocale";
import { useStaticQuery, graphql } from "gatsby";
import useTranslations from "../hooks/useTranslations";

function SEO({ metadata }) {
  const { locale } = useLocale();
  const { description, title, image } = metadata;
  const tr = useTranslations();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const socialMediaImage = site.siteMetadata.siteUrl + image;

  return (
    <Helmet
      htmlAttributes={{
        locale,
      }}
      title={title}
      titleTemplate={`%s | ${tr(site.siteMetadata.title)}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: socialMediaImage,
        },
        {
          property: `twitter:image`,
          content: socialMediaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: title,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
}

export default SEO;
