exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-incident-js": () => import("./../../../src/pages/incident.js" /* webpackChunkName: "component---src-pages-incident-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-observation-js": () => import("./../../../src/pages/observation.js" /* webpackChunkName: "component---src-pages-observation-js" */),
  "component---src-templates-incident-template-js": () => import("./../../../src/templates/incident-template.js" /* webpackChunkName: "component---src-templates-incident-template-js" */),
  "component---src-templates-observation-template-js": () => import("./../../../src/templates/observation-template.js" /* webpackChunkName: "component---src-templates-observation-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

