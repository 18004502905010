import React, { useState } from "react";
import { format } from "date-fns";
import { datePickerStyle } from "../../style/theme";
import { isNil } from "ramda";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import useTranslation from "../../hooks/useTranslations";

export default function DateFiltersw({ setCurrentPage, setFilters, style }) {
  const tr = useTranslation();
  return (
    <div>
      <DayPickerInput
        placeholder={tr("From")}
        inputProps={{
          style: style,
        }}
        clickUnselectsDay={true}
        onDayChange={(day) => {
          setCurrentPage(1);
          setFilters((prevState) => ({
            ...prevState,
            dateAfter: day,
          }));
        }}
      />
      <DayPickerInput
        placeholder={tr("To")}
        inputProps={{
          style: style,
        }}
        onDayChange={(day) => {
          setCurrentPage(1);
          setFilters((prevState) => ({
            ...prevState,
            dateBefore: day,
          }));
        }}
      />
    </div>
  );
}
