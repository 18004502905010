import React from "react";
import styled from "@emotion/styled";
import { MDXProvider } from "@mdx-js/react";
import MdxLink from "./mdx-link";
import useTranslation from "../../hooks/useTranslations";
import { colors } from "../../style/theme";
import { mq } from "../../utils/helper";

export default function Toc({ children }) {
  const tr = useTranslation();
  return (
    <div
      css={{
        border: `3px solid ${colors.black}`,
        borderRadius: "5px",
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: "2rem",
        fontSize: "23px",
        lineHeight: "27.5px",
        [mq[0]]: {
          fontSize: "20px",
        },
      }}
    >
      <h3 css={{ fontWeight: "bold" }}>
        {tr("Here we present brief context and findings from our data:")}
      </h3>
      <MDXProvider
        components={{
          a: styled(MdxLink)({
            color: colors.black,
            marginBottom: "0.3rem",
            display: "inline-block",
            fontWeight: "bold",
            ":hover": {
              color: colors.black,
            },
          }),
        }}
      >
        {children}
      </MDXProvider>
      <div
        css={{
          position: "absolute",
          bottom: "-10%",
          right: "5%",
          backgroundColor: colors.light,
          zIndex: 4,
        }}
      ></div>
    </div>
  );
}
