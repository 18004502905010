import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Album({ images, config }) {
  const items = images.map((item) => {
    return {
      original: item.image,
      thumbnail: item.image,
      description: item.desc || "",
    };
  });
  const isRTL = config ? config.isRTL : false;
  return (
    <ImageGallery
      items={items}
      showThumbnails={false}
      showFullscreenButton={true}
      showPlayButton={false}
      lazyLoad={true}
      showBullets={true}
      isRTL={isRTL}
    />
  );
}

Album.propTypes = {
  images: PropTypes.array,
};

export default Album;
