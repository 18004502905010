import React from "react";
import { PolarArea } from "react-chartjs-2";
import { mq } from "../utils/helper";

const state = {
  type: "polarArea",
  labels: [
    "Airstrikes",
    "Unknown delivery methods",
    "Small Arms",
    "Mortar",
    "Landmine",
    "Rocket Artillery",
    "Execution",
    "Unkown Munition",
    "Placed Explosive",
    "Artillery",
  ],
  datasets: [
    {
      label: "Points",
      backgroundColor: [
        "#7fa3aaba",
        "#af1b43ba",
        "#926a6f9c",
        "#bfa1759c",
        "#ee979ab5",
        "#c3b1cab8",
        "#848c49c7",
        "#00a6a6",
        "#9ea571",
        "#f4cb91",
      ],
      data: [42, 38, 44, 15, 4, 24, 2, 2, 7, 6],
      options: {
        scales: {
          yAxes: [
            {
              stacked: false,
            },
          ],
        },
      },
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
  },
  gridLines: {
    drawOnArea: false,
  },
};

export default function PolarAreaChart() {
  return (
    <div
      css={{
        position: "relative",
        zIndex: 3,
        flexGrow: 2,
        minWidth: "420px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        /* backgroundColor: "black", */
        [mq[0]]: {
          minWidth: "auto",
          display: "block",
          marginBottom: "2rem",
        },
      }}
    >
      <PolarArea data={state} options={options} />
    </div>
  );
}
