import styled from "@emotion/styled";
import { mq } from "../../utils/helper.js";

const Div = styled.div({
  display: "flex",
  [mq[0]]: {
    display: "block",
  },
});

export default Div;
