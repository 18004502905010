import Quote from "../../../../src/components/mdx/quote.js";
import Toc from "../../../../src/components/mdx/toc.js";
import Toi from "../../../../src/components/mdx/text-on-image.js";
import Album from "../../../../src/components/mdx/album.js";
import CaseNote from "../../../../src/components/mdx/case-note-box.js";
import AreaOfControl from "../../../../src/charts/area-of-control.js";
import PolarAreaChart from "../../../../src/charts/polar-area.js";
import AttacksLineChart from "../../../../src/charts/timeline-attacks.js";
import YemeniMap from "../../../../src/charts/map.js";
import Video from "../../../../src/components/video.js";
import Div from "../../../../src/components/mdx/div.js";
import * as React from 'react';
export default {
  Quote,
  Toc,
  Toi,
  Album,
  CaseNote,
  AreaOfControl,
  PolarAreaChart,
  AttacksLineChart,
  YemeniMap,
  Video,
  Div,
  React
};