import React from "react";
import { colors } from "../style/theme";
import LocalizedLink from "./localizedLink";
import Navigation from "./navigation";
import useTranslation from "../hooks/useTranslations";
import usePath from "../hooks/usePath";
import "bootstrap/dist/css/bootstrap.min.css";
import useLocale from "../hooks/useLocale";
import { mq } from "../utils/helper";
// import Footer from "components/Footer";
// import Header from "components/Header";

function Header() {
  const { path } = usePath();
  const { isAra } = useLocale();
  const tr = useTranslation();
  return (
    <div
      css={{
        direction: isAra ? "rtl" : "ltr",
        textAlign: isAra ? "right" : "left",
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 99,
        backgroundColor: "black",
        width: "100%",
        height: "5rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <LocalizedLink to="">
          <div
            css={{
              display: "flex",
              alignItems: "center",
              paddingLeft: isAra ? "auto" : "5rem",
              paddingRight: isAra ? "5rem" : "auto",
              height: "100%",
              [mq[0]]: {
                paddingLeft: isAra ? "auto" : "1rem",
                paddingRight: isAra ? "1rem" : "auto",
              },
            }}
          >
            <img
              css={{ margin: 0 }}
              src="/assets/header-logo-white.svg"
              width="66px"
              height="60px"
            />
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                margin: 0,
                color: colors.light,
                fontSize: "20px",
                lineHeight: "21px",
                width: "240px",
                height: "42px",
                [mq[0]]: {
                  paddingTop: "0.2rem",
                },
              }}
            >
              {tr("Endangering the Media")}
            </div>
          </div>
        </LocalizedLink>

        <div
          css={{
            display: "flex",
            alignItems: "center",
            width: "500px",
            marginRight: "8rem",
            [mq[0]]: {
              display: "none",
            },
          }}
        >
          <Navigation place="layout" />
        </div>
      </div>
    </div>
  );
}

function Footer() {
  const { path } = usePath();
  const { isAra } = useLocale();
  const tr = useTranslation();
  return (
    <div
      css={{
        direction: isAra ? "rtl" : "ltr",
        textAlign: isAra ? "right" : "left",
        borderTop: `1px solid ${colors.primary}`,
        backgroundColor: "black",
        width: "100%",
        height: "5rem",
        display: "flex",
        alignItems: "center",
        [mq[0]]: {
          display: "block",
        },
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <LocalizedLink to="">
          <div
            css={{
              display: "flex",
              alignItems: "center",
              paddingLeft: isAra ? "auto" : "5rem",
              paddingRight: isAra ? "5rem" : "auto",
              height: "100%",
              [mq[0]]: {
                padding: "0.5rem",
              },
            }}
          >
            <img
              css={{ margin: 0 }}
              src="/assets/header-logo-white.svg"
              width="66px"
              height="60px"
            />
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                margin: 0,
                marginTop: "0.3rem",
                color: colors.light,
                fontSize: "20px",
                lineHeight: "21px",
                width: "240px",
                height: "42px",
                [mq[0]]: {
                  width: "100%",
                },
              }}
            >
              {tr("Endangering the Media")}
            </div>
          </div>
        </LocalizedLink>

        <div
          css={{
            display: "flex",
            alignItems: "center",
            marginRight: "8rem",
            marginLeft: "8rem",
            [mq[0]]: {
              display: "none",
            },
          }}
        >
          <a
            css={{
              color: colors.secondary,
              ":hover": {
                color: colors.secondary,
              },
            }}
            href="https://yemeniarchive.org/"
          >
            {" "}
            {tr("Yemeni Archive")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default function Layout({ children, hasFooter = false }) {
  return (
    <div css={{ position: "relative" }}>
      <Header />
      <main>{children}</main>
      {hasFooter && <Footer />}
    </div>
  );
}
