module.exports = {
  apiAuthHeader: "Basic bW5lbW9uaWNhcGk6ZHlqbXQwNWMzN05iS0VPU3AxVlg=",
  apiUrl: "https://api.mnemonic.org",
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    siteLanguage: `en`,
  },
  ar: {
    path: `ar`,
    locale: `ar-AR`,
    siteLanguage: `ar`,
    ogLanguage: `ar_AR`,
  },
};
