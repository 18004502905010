import React from "react";
import styled from "@emotion/styled";
import { IoWarning } from "react-icons/io5";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "./mdx-link";
import { colors } from "../../style/theme";
import useTranslation from "../../hooks/useTranslations";

const Span = styled.span({
  display: "block",
  color: colors.light,
  fontSize: "30px",
  fontWeight: 800,
});

export default function CaseNote({ children, head = false }) {
  const tr = useTranslation();
  return (
    <div
      css={{ position: "relative", display: "flex", justifyContent: "center" }}
    >
      {head && (
        <div
          css={{
            position: "absolute",
            top: "2%",
          }}
        >
          <div
            css={{
              width: "116px",
              height: "116px",
              borderRadius: "50%",
              background: "linear-gradient(180deg, #AF1B43 0%, #791933 100%)",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Span>{tr("CASE")} </Span>
            <Span> {tr("NOTE")} </Span>
          </div>
        </div>
      )}

      <div
        css={{
          backgroundColor: "#C4C4C4",
          marginTop: head ? "5rem" : "2rem",
          marginBottom: "2rem",
          padding: "2rem",
          paddingTop: head ? "5rem" : "2rem",
          lineHeight: "26px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.6)",
          borderRadius: "20px",
        }}
      >
        <MDXProvider
          components={{
            a: styled(MdxLink)({
              color: colors.primary,
              fontWeight: "bold",
              ":hover": {
                color: colors.primary,
              },
            }),
          }}
        >
          {children}
        </MDXProvider>
      </div>
    </div>
  );
}
