import React from "react";
import { Link } from "gatsby";
import { colors } from "../style/theme";
import useTranslation from "../hooks/useTranslations";
import MdxLink from "../components/mdx/mdx-link";
import usePath from "../hooks/usePath";
import useLocale from "../hooks/useLocale";
import { mq } from "../utils/helper";

const style = {
  ul: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    listStyle: "none",
    alignItems: "center",
    margin: 0,
    [mq[0]]: {
      display: "block",
    },
  },
  li: {
    padding: "0.5rem",
    color: colors.light,
    margin: 0,
    textTransform: "uppercase",
    fontWeight: "500",
  },
};

const layoutStyle = style;

const homeStyle = {
  ul: {
    ...style.ul,
    width: "640px",
    [mq[0]]: {
      width: "auto",
      display: "block",
      textAlign: "center",
    },
  },
  li: {
    ...style.li,
    marginTop: "1rem",
    opacity: "0.8",
    fontSize: "20px",
    fontWeight: "900",
    letterSpacing: "0.59px",
    lineHeight: "23px",
  },
};

export const menuItems = (locale = "en") => [
  { id: 1, title: "findings", goto: "findings" },
  { id: 2, title: "data", goto: "map" },
  {
    id: 3,
    title: "investigations",
    goto: `https://yemeniarchive.org/${locale}/investigations`,
  },
  { id: 4, title: "methodology", goto: "methodology" },
];

export default function Navigation({ place }) {
  const tr = useTranslation();
  const { locale, isAra } = useLocale();
  const design = place === "layout" ? layoutStyle : homeStyle;
  const { path } = usePath();
  return (
    <ul css={[design.ul, { direction: isAra ? "rtl" : "ltr" }]}>
      {menuItems(locale).map((item) => (
        <MdxLink key={item.id} href={item.goto}>
          <li
            css={{
              ...design.li,
              backgroundColor:
                place === "layout"
                  ? `/${item.goto}` === path
                    ? colors.primary
                    : "#000"
                  : "none",
            }}
          >
            {tr(item.title)}
          </li>
        </MdxLink>
      ))}

      <Link
        css={{
          paddingTop: "0.3rem",
          ...design.li,
          ":hover": {
            color: colors.light,
          },
        }}
        to={isAra ? `/` : "/ar"}
      >
        <li css={{ marginBottom: isAra ? 0 : "0.3rem" }}>
          {isAra ? "English" : "عربي"}
        </li>
      </Link>
    </ul>
  );
}
