import React from "react";
import useLocale from "../hooks/useLocale";
import translations from "../../config/translations/translations.json";

export default function useTranslations() {
  const { locale } = useLocale();

  return function tr(s) {
    try {
      return translations[locale][s.toLowerCase()]
        ? translations[locale][s.toLowerCase()]
        : s;
    } catch (e) {
      return s;
    }
  };
}
