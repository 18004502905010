import { startsWith } from "ramda";
import React from "react";
import useLocale from "../../hooks/useLocale";
import LocalizedLink from "../localizedLink";

const isHash = (str) => /^#/.test(str);
//const isInternal = (to) => /^\/(?!\/)/.test(to);
const isInternal = (to) => !startsWith("http", to);

// Only use <LocalizedLink /> for internal links
export default function MdxLink({ href, ...props }) {
  const { locale } = useLocale();

  return isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} />
  ) : (
    <LocalizedLink {...props} to={href} />
  );
}
